<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div v-if="wallets.length" class="my-wallets">
        <h5>My Wallets</h5>
        <DataTable
          :value="wallets"
          dataKey="id"
          v-model:wallets="wallets"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="rows"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column
            header="mnemonic"
            field="auToken.mnemonicEng"
            style="word-break: break-all"
            :sortable="true"
          />
          <Column
            header="name"
            field="auToken.nameEng"
            style="word-break: break-all"
            :sortable="true"
          />
          <Column header="amount" field="amount" style="word-break: break-all">
            <template #body="slot">
              <OutputDecimals
                :decimals-value="slot.data.amount"
                :decimals="slot.data.auToken.decimals"
              />
            </template>
          </Column>

          <Column
            header="Blocked"
            field="amountBlocked"
            style="word-break: break-all"
          >
            <template #body="slot">
              <OutputDecimals
                :decimals-value="slot.data.amountBlocked"
                :decimals="slot.data.auToken.decimals"
              />
            </template>
          </Column>
          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
            style="width: 130px"
          >
            <template #body="slot">
              <div data-help="Open">
                <Button
                  icon="pi pi-folder-open ert"
                  class="p-button-rounded p-button-warning m-1"
                  @click="open(slot.data)"
                />
              </div>
              <div data-help="Send">
                <Button
                  icon="pi pi-sign-out"
                  class="send p-button-rounded p-button-warning m-1"
                  @click="send(slot.data)"
                />
              </div>
              <div data-help="Sale">
                <Button
                  icon="pi pi-dollar"
                  class="sale p-button-rounded p-button-warning m-1"
                  @click="sale(slot.data)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-wallets">
        <p>Not any wallets yet</p>
      </div>
    </div>
    <!-- Dialogs -->

    <Dialog
      v-model:visible="openDialog"
      :style="{ width: '450px' }"
      header="Open"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error">You open</p>
        <p class="text-center">
          Name: <b>{{ this.item.auToken.name }}</b>
        </p>
        <p class="text-center">
          Mnemonic: <b>{{ this.item.auToken.mnemonic }}</b>
        </p>
        <p class="text-center">
          ownerAddress: <b>{{ this.item.ownerAddress.value }}</b>
        </p>
        <p class="text-center">
          amount: <b>{{ this.item.amount }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="openDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="openConfirm"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="sendDialog"
      :style="{ width: '450px' }"
      header="Отправить"
      :modal="true"
    >
      <div>
        <label for="transferWallet">Адрес кошелька</label>
      </div>
      <div>
        <InputText
          id="transferWallet"
          v-model="transferWallet"
          style="width: 100%"
        />
      </div>
      <br />
      <div>
        <label for="transferAmount">Сумма перевода</label>
      </div>
      <div>
        <InputText
          id="transferAmount"
          v-model="transferAmount"
          style="width: 100%"
          type="number"
        />
      </div>
      <small class="block m-1 p-error" v-if="!checkWallet">
        Некорректный кошелек
      </small>
      <small class="block m-1 p-error" v-if="!checkAmount">
        Неккоректная сумма
      </small>
      <template #footer>
        <Button
          label="Отмена"
          icon="pi pi-times"
          class="p-button-text"
          @click="sendDialog = false"
        />
        <Button
          :disabled="!checkButton"
          label="Отправить"
          icon="pi pi-check"
          class="p-button-text"
          @click="sendConfirm"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="saleDialog"
      :style="{ width: '450px' }"
      header="Sale"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error">You want to sale</p>
        <p class="text-center">
          Name: <b>{{ this.item.auToken.name }}</b>
        </p>
        <p class="text-center">
          Mnemonic: <b>{{ this.item.auToken.mnemonic }}</b>
        </p>
        <p class="text-center">
          ownerAddress: <b>{{ this.item.ownerAddress.value }}</b>
        </p>
        <p class="text-center">
          amount: <b>{{ this.item.amount }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="saleDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saleConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'
import OutputDecimals from '@/components/edit/OutputDecimals'

export default {
  components: { OutputDecimals },
  name: 'Wallet',
  head() {
    return {
      title: 'Wallet',
    }
  },
  data() {
    return {
      wallets: [],
      tokenList: [],
      item: {},
      openDialog: false,
      sendDialog: false,
      saleDialog: false,
      transferWallet: null,
      transferAmount: null,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    // await this.getTokens()
    await this.getWallets()
  },
  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getWallets()
    },
    async getWallets() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/au_owners', { headers, params })
        this.wallets = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
        // for (const item of walletData)
        // {
        //   [item.name, item.mnemonic, item.decimals] = this.getTokenInfo(item.auToken);
        //   this.wallets.push(item);
        // }
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in au_owners api call',
          group: 'info',
          life: 3000,
        })
      }
    },

    // async getTokens()
    // {
    //   try{
    //     const resp = await this.axios.get("api/au_tokens");
    //     const tokenData = resp.data;
    //     console.log("tokenData", tokenData);
    //     for (const item of tokenData)
    //     {
    //       this.tokenList.push({
    //         id: item.id,
    //         name: item.name,
    //         mnemonic: item.mnemonic,
    //         decimals: item.decimals === undefined ? 0 : item.decimals
    //       });
    //     }
    //   }
    //   catch (e)
    //   {
    //     this.$toast.add({
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: 'Error in au_tokens api call',
    //       group: 'info',
    //       life: 3000,
    //     })
    //   }
    // },

    // getTokenInfo(value){
    //   let id = value.id;
    //   //check value iri or object
    //   if (id !== undefined) return [value.name, value.mnemonic, value.decimals];
    //   id = value.slice(value.lastIndexOf('/') + 1);
    //   const token = this.tokenList.find(item => item.id == id);
    //   if (token === undefined) return ["No name", "-", 0];
    //   return [token.name ,token.mnemonic, token.decimals];
    // },

    open(value) {
      this.openDialog = true
      this.item = value
    },
    send(value) {
      this.sendDialog = true
      this.item = value
    },
    sale(value) {
      this.saleDialog = true
      this.item = value
    },
    openConfirm() {
      this.openDialog = false
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Wallet open',
        group: 'info',
        life: 3000,
      })
    },
    async sendConfirm() {
      this.sendDialog = false
      const params = {
        user: `api/users/${this.$auth.user().id}`,
        createdAt: moment().format('DD.MM.YYYY'),
        type: 'token_transfer',
        description: 'send',
        value: this.transferAmount,
        address: this.item.ownerAddress,
        addressAfter: this.transferWallet,
        isTask: true,
      }
      try {
        await this.axios.post('api/token_events', params)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Wallet send',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Send error',
          group: 'info',
          life: 3000,
        })
      }
    },
    saleConfirm() {
      this.saleDialog = false
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Wallet sale',
        group: 'info',
        life: 3000,
      })
    },
  },
  computed: {
    checkWallet() {
      return (
        this.transferWallet === null ||
        this.transferWallet === '' ||
        this.$filters.correctETH(this.transferWallet)
      )
    },
    checkAmount() {
      return (
        this.transferAmount === null ||
        this.transferAmount === '' ||
        (Number(this.transferAmount) > 0 &&
          Number(this.transferAmount) <= this.item.amountFree)
      )
    },
    checkButton() {
      return (
        this.$filters.correctETH(this.transferWallet) &&
        Number(this.transferAmount) > 0 &&
        Number(this.transferAmount) <= this.item.amountFree
      )
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>
